import ReactDOM from "react-dom/client";
import "src/styles/index.css";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import App from "src/App";
import store from "src/store/store";
import { NotificationProvider } from "src/contexts/NotificationContext";
import { RefreshCronjobProvider } from "./contexts/RefreshCronjobContext";
import CookieContentComponent from "./components/CookieContentComponent";
import { LanguageProvider } from "./contexts/Language";
import "src/localization";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ReduxProvider store={store}>
        <LanguageProvider>
            <HelmetProvider>
                <NotificationProvider>
                    <BrowserRouter>
                        <RefreshCronjobProvider>
                            <App />
                            <CookieContentComponent />
                        </RefreshCronjobProvider>
                    </BrowserRouter>
                </NotificationProvider>
            </HelmetProvider>
        </LanguageProvider>
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
