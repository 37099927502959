import { createSlice } from "@reduxjs/toolkit";
import { IRecipient } from "src/models/recipient";

export interface IRecipientState {
    recipients: IRecipient[];
    reload: number;
}

const initialState: IRecipientState = {
    recipients: [],
    reload: 0,
};

const stateName = "recipients";

const RecipientSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setRecipients: (state, { payload }: { payload: { data: IRecipient[] } }) => {
            payload.data.sort((a, b) =>
                `${a.first_name?.toLowerCase()} ${a.last_name?.toLowerCase()}` >
                `${b.first_name?.toLowerCase()} ${b.last_name?.toLowerCase()}`
                    ? 1
                    : -1
            );
            state.recipients = payload.data;
        },
        addRecipient: (state, { payload }: { payload: { data: IRecipient } }) => {
            state.recipients.push(payload.data);
            state.reload = state.reload + 1;
        },
        updateRecipient: (state, { payload }: { payload: IRecipient }) => {
            const index = state.recipients.findIndex((recipient) => recipient.id === payload.id);
            if (index >= 0) {
                state.recipients[index] = payload;
            }
        },
    },
});

///// Export state values ////////
export const selectRecipients = (state: any) => (state.entities[stateName] as IRecipientState).recipients;
export const selectRecipientsState = (state: any): IRecipient[] => state.entities[stateName].recipients;

///// Export actions ///////
export const { setRecipients, addRecipient, updateRecipient } = RecipientSlice.actions;

export { stateName, RecipientSlice };
