import { RouteObject, useRoutes } from "react-router-dom";
import UnauthenticatedRoutes from "./routes/unauthenticated";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "./store/state/auth.state";
import { useEffect, useState } from "react";
import AuthenticatedRoutes from "./routes/authenticated";
import AutoLoad from "./components/AutoLoad";

function App() {
    const isAuthenticated = useSelector(selectIsLoggedIn);
    const [content, setContent] = useState<RouteObject[]>(UnauthenticatedRoutes);

    useEffect(() => {
        if (isAuthenticated) setContent(AuthenticatedRoutes);
        else setContent(UnauthenticatedRoutes);
    }, [isAuthenticated]);

    return <AutoLoad>{useRoutes(content)}</AutoLoad>;
}

export default App;
